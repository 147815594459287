import React, { useEffect } from "react";
import { useState } from "react";

import "./copyright.scss";
import info from "../../assets/images/copyright.png";
import view from "../../assets/images/view.png";
import viewbg from "../../assets/images/view-2.png";

import "maplibre-gl/dist/maplibre-gl.css";

const Copyrightattribute = ({ testingMapLoad }) => {
  const [toggle, setToggle] = useState(true);

  const [viewClass, setViewClass] = useState(false);

  const handlePano = () => {
    setViewClass((prevViewClass) => !prevViewClass);
    testingMapLoad();
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="copyright">
        <div className="view" onClick={handlePano}>
          <img src={viewClass ? viewbg : view} alt="view" />
        </div>

        <>
          {toggle && (
            <span>
              © {currentYear} Galli Maps. All rights reserved. &nbsp; &nbsp;
            </span>
          )}
        </>
        <div className="info">
          <img src={info} alt="info" onClick={() => setToggle(!toggle)} />
        </div>
      </div>
    </>
  );
};

export default Copyrightattribute;
