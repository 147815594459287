import React, { useEffect, useRef, useState } from "react";
import pinImage from "../../assets/images/pin.png";
import "./panoromaview.scss";
import pannellum from "pannellum";
import Home from "../../pages/Home";
import Minimizedmap from "../minimizedmap/Minimizedmap";
import cross from "../../assets/images/crossbtn.png";
import { useMapContext } from "../context/Mapcontext";

const Panoromic = ({ imagePath, uniqueId, lng, lat, onPanoClick }) => {
  const pannellumContainer = useRef(null);
  const [viewerNew, setViewerNew] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [hotspotPosition, setHotspotPosition] = useState({ yaw: 0, pitch: 0 });
  const [mapVisible, setMapVisible] = useState(false); // State to control map visibility
  const { setYawDataValue, setPitchDataValue } = useMapContext();
  const [activeHotspot, setActiveHotspot] = useState(null);

  useEffect(() => {
    const viewer = window.pannellum.viewer(pannellumContainer.current, {
      preview: imagePath,
      type: "equirectangular",
      panorama: imagePath,
      autoLoad: true,
      // compass: true, // Disable compass by default
      showZoomCtrl: false,
      hotSpots: [],
      onmousemove: function (event) {
        if (viewerNew) {
          let yawData = viewerNew.getYaw();

          if (yawData < 0) {
            yawData = 180 + (180 + yawData);
          }
          setHotspotPosition({ yaw: yawData, pitch: viewerNew.getPitch() });
        }
      },
    });

    setViewerNew(viewer);

    return () => {
      viewer.destroy();
    };
  }, [uniqueId, imagePath]);

  const handleCanvasClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; // X position relative to the image
    const y = e.clientY - rect.top; // Y position relative to the image

    // Calculate pitch and yaw based on click position
    const pitch = (y / rect.height) * 180 - 90; // Calculate pitch
    const yaw = (x / rect.width) * 360 - 180; // Calculate yaw

    // Pass pitch and yaw back to parent component
    onPanoClick(pitch, yaw);

    if (viewerNew) {
      setClicked(true);
      const yawData = viewerNew.getYaw();
      const pitchData = viewerNew.getPitch();
      // Call setYawDataValue with the yawData
      setYawDataValue(yawData);
      setPitchDataValue(pitchData);
      // console.log(yawData, pitchData);
      if (activeHotspot) {
        // Update position of the active hotspot
        activeHotspot.pitch = pitchData;
        activeHotspot.yaw = yawData;
        viewerNew.removeHotSpot(activeHotspot.hotSpotId, activeHotspot.sceneId);
      } else {
        // Add a new hotspot
        const newHotspot = viewerNew.addHotSpot({
          pitch: pitchData,
          yaw: yawData,
          type: "custom",
          sceneId: uniqueId,
          cssClass: "custom-hotspot",
          createTooltipFunc: hotspot,
        });
        // Update activeHotspot only if it doesn't exist
        if (!activeHotspot) {
          setActiveHotspot(newHotspot);
        }
        // console.log(activeHotspot);
      }
    }
  };

  // Hot spot creation function
  function hotspot(hotSpotDiv, args) {
    hotSpotDiv.classList.add("custom-tooltip");

    var image = document.createElement("img"); // Create img element
    image.src = pinImage; // Set src attribute to the pin image URL
    hotSpotDiv.appendChild(image);

    image.style.width = "30px"; // Set width of the image
    image.style.height = "30px"; // Set height of the image
    image.style.marginLeft = "-10px"; // Adjust image position
    image.style.marginTop = "-10px"; // Adjust image position
  }

  return (
    <>
      <div
        ref={pannellumContainer}
        style={{ position: "relative" }}
        onClick={handleCanvasClick}
      ></div>
    </>
  );
};

export default Panoromic;
