import React, { useEffect, useState } from "react";
import "./departmentlist.scss";
import { Button } from "react-bootstrap";
import { useMapContext } from "../context/Mapcontext";
import Direction from "../directions/Direction";
import getdirection from "../../assets/images/get-direction.png";
import share from "../../assets/images/sharebtn.png";
import cancel from "../../assets/images/remove.png";
import { Marker } from "react-map-gl/maplibre";
import pin from "../../assets/images/locationpin.png";

const Departmentlist = ({ name, ward, municipality, latitude, longitude }) => {
  const [srcLat, setSrcLat] = useState(latitude || null);
  const [srcLng, setSrcLng] = useState(longitude || null);
  const [direction, setDirection] = useState(false);
  const { isBooleanValue, setBooleanValue } = useMapContext();
  const [listName, setListName] = useState(name || "");
  const [show, setShow] = useState(true);
  // direction, setDirectionValue

  // Update state when props change
  useEffect(() => {
    setSrcLat(latitude || null);
    setSrcLng(longitude || null);
    setListName(name || "");
    setShow(true);
  }, [name, ward, municipality, latitude, longitude]);

  useEffect(() => {
    if (!isBooleanValue && direction) {
      setDirection(!direction);
      setShow(false);
    }
  }, [direction, isBooleanValue]);

  const handleDirection = () => {
    // console.log(srcLat, srcLng, listName);
    // setDirectionValue(!direction);
    setDirection(!direction);
    if (isBooleanValue) {
      setBooleanValue(isBooleanValue);
    } else {
      setBooleanValue(!isBooleanValue);
    }
  };

  const handleClear = () => {
    setListName("");
    setSrcLat(null);
    setSrcLng(null);
    setShow(false);
  };

  return (
    <>
      {show &&
        listName && ( // Conditional rendering to check if point is not null
          <>
            <div className={isBooleanValue ? "pointinfo-select" : "point-info"}>
              <ul>
                <li>
                  <div className="point-title">
                    <span>{listName}</span>
                    <img src={cancel} alt="close" onClick={handleClear} />
                  </div>
                  {/* <br /> */}
                  <span>
                    {municipality}-{ward}
                  </span>
                </li>
              </ul>
              <hr />
              <div className="point-direction">
                <Button
                  className="direction-btn"
                  onClick={() => {
                    handleDirection();
                  }}
                >
                  <img src={getdirection} alt="get-direction" />
                  <span>Directions</span>
                </Button>
                <Button className="share-btn">
                  <img src={share} alt="share" />
                </Button>
              </div>
            </div>
            <Marker longitude={longitude} latitude={latitude} anchor="bottom">
              <img src={pin} alt="pin" style={{ height: "40px" }} />
            </Marker>
          </>
        )}
      {direction && ( // Conditional rendering to check if both direction and data are not null
        <div>
          <Direction stop={listName} secondLat={srcLat} secondLng={srcLng} />
        </div>
      )}
    </>
  );
};

export default Departmentlist;
