import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { Layer, Marker, Source } from "react-map-gl/maplibre";
import mueseumicon from "../../assets/images/mueseum.png";
import "./heritagewalk.scss";
import { heritageImageUrl, heritageWalkUrl } from "../../urls/urls";
import { useMapContext } from "../context/Mapcontext";

import { useMap } from "react-map-gl/maplibre";
import maplibregl from "maplibre-gl";
import pin from "../../assets/images/pin.png";
import museummarker from "../../assets/images/museum-1marker.png";

const Heritagewalk = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [heritage, setHeritage] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [view, setView] = useState(false);
  const [selectedtourName, setSelectedTourName] = useState("");
  // State to store the selected Tour Id
  const [selectedTourId, setSelectedTourId] = useState(null);
  const {
    amenityWord,
    setAmenityValue,
    isBooleanValue,
    setBooleanValue,
    setMapCoordinates,
  } = useMapContext();
  const { current: map } = useMap();

  const lineStyle = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      // "line-color": "rgba(0,255,0,0.5)",
      "line-color": "#E37547",
      "line-width": 8,
    },
  };

  useEffect(() => {
    if (amenityWord === "") {
      setSelectedTourId(null);
      setSelectedTourName("");
      setClicked(false);
    }
  }, [amenityWord, isBooleanValue, setBooleanValue]);

  // Handle button click to set the selected Tour Id and Tour Name
  const handleClick = (tourId, tourName) => {
    setSelectedTourId(tourId);
    setSelectedTourName(tourName);
    setClicked(true);
    setAmenityValue(tourName);
    if (isBooleanValue) {
      setBooleanValue(isBooleanValue);
    } else {
      setBooleanValue(!isBooleanValue);
    }
  };

  // Filter the data for the selected Tour Id
  const selectedTourData = heritage.filter(
    (data) => data.properties["Tour Id"] === selectedTourId
  );

  const handleClose = () => {
    setClicked(false);
    setShow(false);
    setSelectedTourName("");
    setSelectedTourId(null);
  };

  useEffect(() => {
    const fetchHeritageWalk = async () => {
      try {
        setLoading(true);
        const response = await fetch(heritageWalkUrl);
        if (response.ok) {
          const data = await response.json();
          setHeritage(data.features);
        } else {
          console.log("Failed to fetch events:", response);
        }
      } catch (error) {
        console.error("Failed to fetch heritage walk", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHeritageWalk();
  }, []);

  // Step 1: Group tour names by location
  const groupedLocations = heritage.reduce((acc, data) => {
    const location = data.properties.location;
    const tourName = data.properties["Tour Name"];
    const tourId = data.properties["Tour Id"];

    // Only add if tourName exists
    if (tourName) {
      if (!acc[location]) {
        acc[location] = new Set(); // Use a Set to ensure unique tour names per location
      }
      acc[location].add(tourName); // Add tour name to the set for this location
    }
    return acc;
  }, {});

  const prevTourDataRef = useRef();

  useEffect(() => {
    if (prevTourDataRef.current !== selectedTourData) {
      prevTourDataRef.current = selectedTourData;
    }
  }, [selectedTourData]);

  // Prepare an array to hold all MultiLineString features
  const multiLineFeatures = [];

  // Iterate through the selectedTourData
  selectedTourData.map((data, index) => {
    // Check if the feature is a Point and plot it
    if (data.geometry.type === "Point") {
      return (
        <Marker
          key={`marker-${index}`} // Ensure a unique key
          latitude={data.geometry.coordinates[1]}
          longitude={data.geometry.coordinates[0]}
        >
          <div className="text-center">
            <img
              style={{ height: "40px", width: "40px" }}
              src={museummarker} // Your pin image here
              alt="tourpin"
            />
            <br />
            <span style={{ fontWeight: "bolder" }}>
              {data.properties.POINT_NAME}
            </span>
          </div>
        </Marker>
      );
    }

    // If it's a MultiLineString, add it to the array of features
    if (data.geometry.type === "MultiLineString") {
      multiLineFeatures.push(data);
    }

    return null; // In case the geometry type is unsupported
  });

  const handleView = () => {
    setView(true);
    // setSelectedTourId(data.properties["Tour Id"]);
    setShow(false);
  };

  // Utility function to calculate the bounding box and center
  const calculateBoundingBox = (points) => {
    const lats = points.map((p) => p.geometry.coordinates[1]);
    const lngs = points.map((p) => p.geometry.coordinates[0]);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    return { centerLat, centerLng };
  };

  // Assuming mapRef is a reference to your Map instance
  useEffect(() => {
    // Get all 'Point' features in selectedTourData
    const points = selectedTourData.filter(
      (data) => data.geometry.type === "Point"
    );

    if (points.length > 0) {
      // Calculate the center of the bounding box for the points
      const { centerLat, centerLng } = calculateBoundingBox(points);

      // Fly to the calculated center
      if (map) {
        map.flyTo({
          center: [centerLng, centerLat],
          zoom: 16, // Adjust zoom as needed
          speed: 1, // Controls the speed of the flight animation
          curve: 1, // Controls the easing of the flight animation
          essential: true,
          duration: 2000,
        });
      }
    }
  }, [selectedTourData, map]);

  return (
    <>
      <div className="heritage-box">
        <div style={{ padding: "8px" }} onClick={() => setShow(true)}>
          <Button
            style={{
              width: "10vw",
              borderRadius: "20px",
              marginLeft: "10px",
              backgroundColor: show ? "#E37547" : "",
            }}
          >
            <img src={mueseumicon} alt="heritagewalk" />
            <span>Heritage Walk</span>
          </Button>
        </div>
      </div>

      {selectedTourData.length > 0
        ? selectedTourData.map((data, index) => {
            if (data.geometry.type === "Point") {
              return (
                <Marker
                  key={`marker-${index}`}
                  latitude={data.geometry.coordinates[1]}
                  longitude={data.geometry.coordinates[0]}
                >
                  <div className="text-center">
                    <img
                      style={{ height: "40px", width: "40px" }}
                      src={museummarker}
                      alt="tourpin"
                    />
                    <br />
                    <span style={{ fontWeight: "bolder" }}>
                      {data.properties.POINT_NAME}
                    </span>
                  </div>
                </Marker>
              );
            }
            return null;
          })
        : ""}

      {/* Render the lines */}
      {multiLineFeatures.length > 0 && (
        <Source
          key="multiLineStrings"
          type="geojson"
          data={{ type: "FeatureCollection", features: multiLineFeatures }}
        >
          <Layer {...lineStyle} />
        </Source>
      )}

      <Modal show={show} onHide={handleClose} size={clicked ? "lg" : "md"}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedtourName ? selectedtourName : "Heritage Walk"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="heritage-content">
            {/* Step 2: Render the locations with their unique tour names */}
            {!clicked
              ? Object.entries(groupedLocations).map(
                  ([location, tourNames], index) => (
                    <div key={index}>
                      <div
                        className="location-title"
                        style={{ marginBottom: "10px" }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            paddingBottom: "10px",
                          }}
                        >
                          {location}
                        </span>
                      </div>

                      {/* Find the tour data from heritage based on tour name */}
                      {[...tourNames].map((tourName, idx) => {
                        const tourData = heritage.find(
                          (data) => data.properties["Tour Name"] === tourName
                        );

                        return (
                          <Button
                            key={idx}
                            onClick={
                              () =>
                                handleClick(
                                  tourData?.properties["Tour Id"],
                                  tourName
                                ) // Pass both Tour Id and Tour Name
                            }
                            style={{
                              backgroundColor: "#FEE2D6",
                              border: "none",
                              marginBottom: "10px",
                              marginTop: "5px",
                              color: "#812c19",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {tourName}
                          </Button>
                        );
                      })}
                    </div>
                  )
                )
              : selectedTourData.map((data, index) => (
                  <div key={index}>
                    {/* Show the button only once, for the first item */}
                    {index === 0 && (
                      <Button
                        style={{
                          backgroundColor: "#E37547",
                          border: "none",
                          marginBottom: "10px",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                        onClick={() => handleView()} // Set the selected tour id
                      >
                        View in map
                      </Button>
                    )}
                    {data.properties.Description === "" ? (
                      ""
                    ) : (
                      <>
                        <h5 style={{ fontWeight: "bold" }}>
                          {data.properties["POINT_NAME"]}
                        </h5>
                        <p>{data.properties.Description}</p>
                        {data.properties.image && (
                          <img
                            src={`${heritageImageUrl}${data.properties.image}`}
                            alt={data.properties["POINT_NAME"]}
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "10px",
                              marginBottom: "10px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Heritagewalk;
