import React from "react";
import Panoromic from "../../components/360view/Panoromic";

const Panodirection = () => {
  return (
    <div>
      <Panoromic style={{ height: "100%" }} />
    </div>
  );
};

export default Panodirection;
