import React, { useState, useEffect } from "react";
import { searchFromCurrentLocationUrl } from "../../urls/urls";
import { Layer, Source, Marker } from "react-map-gl/maplibre";
import "./searchpoint.scss";
import Button from "react-bootstrap/Button";
import getdirection from "../../assets/images/get-direction.png";
import share from "../../assets/images/sharebtn.png";
import cancel from "../../assets/images/remove.png";
import pin from "../../assets/images/locationpin.png";
import Direction from "../directions/Direction";
import { useMapContext } from "../context/Mapcontext";

const Searchpoint = ({ name, settingZoomLevels, latitude, longitude }) => {
  const [place, setPlace] = useState(null);
  const [direction, setDirection] = useState(false);
  const [dstLat, setDstLat] = useState(null);
  const [dstLng, setDstLng] = useState(null);
  const [marker, setMarker] = useState(true);
  const {
    setMapCoordinates,
    isBooleanValue,
    setBooleanValue,
    // direction,
    // setDirectionValue,
    amenityWord,
    panoOn,
    setPanoOnValue,
  } = useMapContext();

  //line style
  const lineStyle = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#E37547",
      "line-width": 8,
    },
  };

  //polygon style
  const polygonStyle = {
    id: "maine",
    type: "fill",
    source: "maine",
    layout: {},
    paint: {
      "fill-color": "#8F95B2",
      "fill-opacity": 0.6,
    },
  };

  useEffect(() => {
    const searchCurrentLocation = async (url) => {
      try {
        const response = await fetch(url);
        const geojson = await response.json();

        if (geojson.success) {
          setPlace(geojson.data);
          const features = geojson.data.features.map((feature, index) => {
            const { properties, geometry } = feature;

            const coordinates = geometry.coordinates;
            let formattedFeature = null;
            if (index === 0) {
              if (feature.geometry.type === "Point") {
                // Handle Point geometry
                const [lng, lat] = coordinates;
                settingZoomLevels(lng, lat, 18);
                setMapCoordinates(lat, lng);
                setDstLat(lat);
                setDstLng(lng);
                formattedFeature = {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [lng, lat],
                  },
                  properties: feature.properties,
                };
              } else if (feature.geometry.type === "Polygon") {
                // Handle Polygon geometry
                const polygonCoordinates = coordinates[0]; // Assuming the first set of coordinates represents the outer ring of the polygon
                settingZoomLevels(
                  polygonCoordinates[0][0],
                  polygonCoordinates[0][1],
                  18
                ); // Assuming the first point of the polygon for setting zoom
                setDstLng(polygonCoordinates[0][0]);
                setDstLat(polygonCoordinates[0][1]);
                setMapCoordinates(
                  polygonCoordinates[0][1],
                  polygonCoordinates[0][0]
                );
                formattedFeature = {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [polygonCoordinates],
                  },
                  properties: feature.properties,
                };
              } else if (feature.geometry.type === "MultiPolygon") {
                // Handle MultiPolygon geometry
                const multiPolygonCoordinates = coordinates.map(
                  (polygon) => polygon[0][0]
                ); // Assuming the first point of the first polygon for setting zoom

                settingZoomLevels(
                  multiPolygonCoordinates[0][0],
                  multiPolygonCoordinates[0][1],
                  18
                ); // Assuming the first point of the first polygon for setting zoom
                setDstLat(multiPolygonCoordinates[0][1]);
                setDstLng(multiPolygonCoordinates[0][0]);
                setMapCoordinates(
                  multiPolygonCoordinates[0][1],
                  multiPolygonCoordinates[0][0]
                );
                // console.log(multiPolygonCoordinates[0][0]);
                formattedFeature = {
                  type: "Feature",
                  geometry: {
                    type: "MultiPolygon",
                    coordinates: [multiPolygonCoordinates],
                  },
                  properties: feature.properties,
                };
              } else if (feature.geometry.type === "LineString") {
                // Handle LineString geometry
                settingZoomLevels(coordinates[0][0], coordinates[0][1], 13); // Assuming the first point of the LineString for setting zoom
                setDstLat(coordinates[0][1]);
                setDstLng(coordinates[0][0]);
                setMapCoordinates(coordinates[0][1], coordinates[0][0]);
                formattedFeature = {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: [coordinates],
                  },
                  properties: feature.properties,
                };
              } else if (feature.geometry.type === "MultiLineString") {
                // Handle MultiLineString geometry
                const multiLineStringCoordinates = coordinates.map(
                  (lineString) => lineString[0]
                );
                settingZoomLevels(
                  multiLineStringCoordinates[0][0],
                  multiLineStringCoordinates[0][1],
                  16
                ); // Assuming the first point of the first LineString for setting zoom
                setDstLat(multiLineStringCoordinates[0][1]);
                setDstLng(multiLineStringCoordinates[0][0]);
                setMapCoordinates(
                  multiLineStringCoordinates[0][1],
                  multiLineStringCoordinates[0][0]
                );
                formattedFeature = {
                  type: "Feature",
                  geometry: {
                    type: "MultiLineString",
                    coordinates: [multiLineStringCoordinates],
                  },
                  properties: feature.properties,
                };
              }
            }
            return formattedFeature;
          });
          return {
            type: "FeatureCollection",
            features: features.filter((feature) => feature !== null),
          };
        } else {
          console.log("failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching current location:", error);
      }
    };
    // if (name) {
    //   setDirection(false); // Set direction to false when name is provided
    // }
    if (name && latitude !== null && longitude !== null) {
      if (direction) {
        setDirection(!direction);
      }
      if (panoOn) {
        setPanoOnValue(panoOn);
      } else {
        setPanoOnValue(!panoOn);
      }
      const url = `${searchFromCurrentLocationUrl}&name=${name}&currentLat=${latitude}&currentLng=${longitude}`;
      searchCurrentLocation(url);
    }
    if (name && latitude === null && longitude === null) {
      if (direction) {
        setDirection(!direction);
      }
      if (panoOn) {
        setPanoOnValue(panoOn);
      } else {
        setPanoOnValue(!panoOn);
      }
      const url = `${searchFromCurrentLocationUrl}&name=${name}&currentLat=${27.677106}&currentLng=${85.32239}`;
      searchCurrentLocation(url);
    }
  }, [name]);

  const handleClear = () => {
    setPlace(null);
    setPanoOnValue(false);
  };

  useEffect(() => {
    if (amenityWord !== "") {
      setPlace(null);
    }
  }, [amenityWord, place]);

  // Clear previous data when the name parameter changes
  useEffect(() => {
    setPlace(null);
  }, [name]);

  if (!place) {
    // If no data, return null or a loading indicator
    return null;
  }
  const decodedName = decodeURIComponent(name);

  const handleDirectionClick = () => {
    setMarker(false);
    setDirection(!direction);
    // setDirectionValue(!direction);
    // console.log(!direction);
    // console.log(decodedName);
    // console.log(dstLat);
    // console.log(dstLng);

    if (isBooleanValue) {
      setBooleanValue(isBooleanValue);
    } else {
      setBooleanValue(!isBooleanValue);
    }
  };

  return (
    <>
      <div className="point">
        {/* {marker && ( */}
        <Source id="mymap" type="geojson" data={place}>
          {place.features?.map((feat, index) => {
            let layer = null;
            // Check if it's the first feature of each type
            if (index === 0) {
              if (feat.geometry.type === "Point") {
                // layer = <Layer key={index} {...pointStyle} />;
                const point = feat.geometry.coordinates;
                layer = (
                  <Marker
                    key={index}
                    longitude={point[0]}
                    latitude={point[1]}
                    anchor="bottom"
                  >
                    <img src={pin} alt="pin" style={{ height: "40px" }} />
                  </Marker>
                );
              } else if (
                feat.geometry.type === "Polygon" ||
                feat.geometry.type === "MultiPolygon"
              ) {
                layer = <Layer key={index} {...polygonStyle} />;
              } else if (
                feat.geometry.type === "LineString" ||
                feat.geometry.type === "MultiLineString"
              ) {
                layer = <Layer key={index} {...lineStyle} />;
              }
            }

            return layer;
          })}
        </Source>
        {/* )} */}
        {direction ? (
          ""
        ) : (
          <div
            className={isBooleanValue ? "pointinfo-select" : "point-info"}
            // style={{ display: direction ? "none" : "block" }}
          >
            <ul>
              {place.features?.slice(0, 1).map((feature) => (
                <li key={feature.properties.searchedItem}>
                  <div className="point-title">
                    <span>
                      {feature.properties.searchedItem
                        ? feature.properties.searchedItem
                        : feature.properties.district}
                    </span>

                    <img src={cancel} alt="close" onClick={handleClear} />
                  </div>

                  <span>{feature.properties.province}</span>

                  <br />
                  <span>
                    {feature.properties.municipality
                      ? `${feature.properties.municipality}, ${feature.properties.ward}`
                      : ""}
                  </span>
                </li>
              ))}
            </ul>
            <hr />
            <div className="point-direction">
              <Button
                className="direction-btn"
                onClick={() => {
                  handleDirectionClick();
                }}
              >
                <img src={getdirection} alt="get-direction" />
                <span>Directions</span>
              </Button>

              <Button className="share-btn">
                <img src={share} alt="share" />
              </Button>
            </div>
          </div>
        )}
      </div>
      {direction && dstLat && dstLng && decodedName && (
        <div>
          <Direction stop={decodedName} secondLat={dstLat} secondLng={dstLng} />
        </div>
      )}
    </>
  );
};

export default Searchpoint;
