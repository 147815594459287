import React from "react";
import Home from "../Home";
import Direction from "../../components/directions/Direction";
import { MapProvider } from "react-map-gl";

const Directionroute = () => {
  return (
    <>
      <MapProvider>
        <Home />
        <Direction />
      </MapProvider>
    </>
  );
};

export default Directionroute;
