import {
  autocompleteUrl,
  checkDepartmentListUrl,
  departmentUrl,
  galliSearch,
} from "../../urls/urls";

const departmentExists = async (textWord) => {
  if (textWord.length > 2) {
    try {
      const url = `${departmentUrl}&word=${textWord}`;
      let result = await (await fetch(url)).json();
      if (result.length > 0) {
        let imageUrlData = JSON.parse(JSON.parse(result[0].logo))[0].image;
        return {
          logo: imageUrlData,
          name: result[0].actualname,
        };
      } else {
        return {
          logo: null,
          name: null,
        };
      }
    } catch (error) {
      console.error("error fetching datas", error);
    }
  }
};

const autoCompleteFetchDatas = async (textWord, latitude, longitude) => {
  if (textWord.length > 2) {
    try {
      const url = `${autocompleteUrl}&word=${textWord}&lat=${latitude}&lng=${longitude}`;
      let response = await fetch(url);
      let result = await response.json();
      if (result.success) {
        let resultArrays = [];
        let datas = result.data;
        datas.map((item, index) => {
          resultArrays.push({ name: item.name, logo: item.logo });
        });
        return resultArrays;
        // return result.data;
      }
      return [];
    } catch (error) {
      console.error("error fetching datas", error);
    }
  }
};

const fetchAllDepartmentResult = async (
  departmentName,
  currentLat,
  currentLng,
  minlatbound,
  minlngbound,
  maxlatbound,
  maxlngbound
) => {
  let url = `${checkDepartmentListUrl}&name=${departmentName}&currentLat=${currentLat}&currentLng=${currentLng}&minlatbound=${minlatbound}&minlngbound=${minlngbound}&maxlatbound=${maxlatbound}&maxlngbound=${maxlngbound}`;
  return await fetchDatas(url);
};

const fetchDatas = async (url) => {
  return await (await fetch(url)).json();
};

const searchCurrentLocation = async (url) => {
  const response = await fetch(url);
  const geojson = await response.json();
  if (geojson.success) {
    return geojson.data;
  }
  return [];
};

export {
  autoCompleteFetchDatas,
  fetchAllDepartmentResult,
  searchCurrentLocation,
  departmentExists,
};
