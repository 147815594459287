import React, { useState } from "react";
import "./login.scss";
import { Button, Form } from "react-bootstrap";
import { getCodeUrl } from "../../../urls/urls";
import Banner from "../Banner/Banner";
import { useNavigate } from "react-router-dom";

const myStorage = window.localStorage;

const Login = () => {
  const [user_id, setUser_id] = useState("");
  const [phone, setPhone] = useState("");

  const handleChange = (event) => {
    setPhone(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `${getCodeUrl}${phone}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data) {
        setUser_id(data.user_id);
        myStorage.setItem("user_id", data.user_id);
        navigate("/verify");
        console.log(data.user_id);
      } else {
        console.log("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const handleSubmit = async (event, url) => {
  //   event.preventDefault();

  //   try {
  //     const response = await fetch(url);
  //     const data = await response.json();

  //     if (data) {
  //       setUser_id(data.user_id);
  //       myStorage.setItem("user_id", data.user_id);
  //       navigate("/verify");
  //       console.log(data.user_id);
  //     } else {
  //       console.log("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // if (phone) {
  //   const url = `${getCodeUrl}${phone}`;
  //   handleSubmit(url);
  // }

  return (
    <div className="login">
      <Banner />

      <div className="login-box">
        <div className="login-header">
          <h4>Welcome to Galli Maps</h4>
        </div>
        {/* onSubmit={(event) => handleSubmit(event)} */}
        <Form onSubmit={handleSubmit}>
          <label htmlFor="Phone">Enter your phone number</label>
          <br />
          <div className="num">
            <span>+977</span>
            <input
              type="phone"
              id="phone"
              name="phone"
              value={phone}
              onChange={handleChange}
              placeholder="Mobile No"
              required
            />
          </div>
          <br />
          <Button type="submit">
            <span>Confirm</span>
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
