import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";

import hospital from "../../assets/images/hospital(1).png";
import museum from "../../assets/images/mueseum.png";
import atm from "../../assets/images/atmicon.png";
import drugs from "../../assets/images/drugs.png";

import { Layer, Marker, Source, useMap, Popup } from "react-map-gl/maplibre";
import restaurant from "../../assets/images/restaurant.png";
import fuel from "../../assets/images/gas-station.png";
import toilet from "../../assets/images/toilet(2).png";
import attraction from "../../assets/images/attraction.png";
import park from "../../assets/images/park.png";
import bank from "../../assets/images/bank(2).png";
import bar from "../../assets/images/bar.png";
import hotel from "../../assets/images/hotel.png";
import library from "../../assets/images/library.png";
import clinic from "../../assets/images/clinic.png";
import theatre from "../../assets/images/theatre.png";
// import laundry from "../../assets/images/laundry.png";
import hairdressing from "../../assets/images/hairdressing.png";
import carwash from "../../assets/images/car-wash.png";
import beautyshop from "../../assets/images/beauty-salon.png";
import nightclub from "../../assets/images/disco-ball.png";
import cafe from "../../assets/images/coffee-cup.png";
import cook from "../../assets/images/cook.png";
import store from "../../assets/images/store.png";
import meat from "../../assets/images/meat.png";
import parking from "../../assets/images/parking.png";
import cinema from "../../assets/images/cinema.png";
import carrental from "../../assets/images/car-rental.png";
import left from "../../assets/images/arrow-left.png";
import right from "../../assets/images/arrow-right.png";
import cross from "../../assets/images/cross.png";
import more from "../../assets/images/more.png";
import restro from "../../assets/images/restro.png";
import cash from "../../assets/images/cash.png";
import rest from "../../assets/images/restroom.png";
import baricon from "../../assets/images/bar-2.png";
import parkicon from "../../assets/images/parking-2.png";
import stayicon from "../../assets/images/stay.png";
import cafeicon from "../../assets/images/cafes.png";
import bankicon from "../../assets/images/banks.png";
import fuelicon from "../../assets/images/fuels.png";
import atmicon from "../../assets/images/cash.png";
import pharmicon from "../../assets/images/meds.png";
import clinicicon from "../../assets/images/hospital-2.png";
import toileticon from "../../assets/images/restroom.png";
import restroicon from "../../assets/images/restro.png";
import mueseumicon from "../../assets/images/mueseumicon.png";
import attracticon from "../../assets/images/attract.png";
import clinicon from "../../assets/images/clinic2.png";
import laundryicon from "../../assets/images/laundryicon (2).png";
import park_icon from "../../assets/images/parkicon.png";
import librariesicon from "../../assets/images/libraries.png";
import carrenticon from "../../assets/images/car_rent.png";
import parlouricon from "../../assets/images/parlour.png";
import nightclubicon from "../../assets/images/nightclub.png";
import salonicon from "../../assets/images/salon.png";
import meaticon from "../../assets/images/meatshop.png";
import bakeryicon from "../../assets/images/bakery.png";
import sportsicon from "../../assets/images/sports.png";
import carwashicon from "../../assets/images/car_wash.png";
import theatreicon from "../../assets/images/film.png";
import cinemaicon from "../../assets/images/clapperboard.png";
import esewaicon from "../../assets/images/esewa.jpg";
import twhite from "../../assets/images/toiletwhite.png";
import rwhite from "../../assets/images/restrowhite.png";
import hwhite from "../../assets/images/plus.png";
import bwhite from "../../assets/images/bankwhite.png";
import awhite from "../../assets/images/atmwhite.png";
import staywhite from "../../assets/images/hotelwhite.png";
import tmark from "../../assets/images/toilet-1marker.png";
import parkmark from "../../assets/images/parkmarker.png";
import hmark from "../../assets/images/hospitalmarker.png";
import cmark from "../../assets/images/cafe-1marker.png";
import rmark from "../../assets/images/restaurantmarker.png";
import amark from "../../assets/images/atm-1marker.png";
import bmark from "../../assets/images/bank-1marker.png";
import hairmarker from "../../assets/images/hairdresser-1marker.png";
import lmarker from "../../assets/images/lodgemarker.png";
import butchermarker from "../../assets/images/butcher-1marker.png";
import cmarker from "../../assets/images/clinic-1marker.png";
import pmarker from "../../assets/images/park-1marker.png";
import attractionmarker from "../../assets/images/attraction-1marker.png";
import laundrymarker from "../../assets/images/laundry-1marker.png";
import bakerymarker from "../../assets/images/bakery-1marker.png";
import parkingmarker from "../../assets/images/parking-1marker.png";
import moviesmarker from "../../assets/images/movies-1marker.png";
import carmarker from "../../assets/images/carwash-1marker.png";
import barmarker from "../../assets/images/bar-1marker.png";
import museummarker from "../../assets/images/museum-1marker.png";
import pharmacymarker from "../../assets/images/pharmacy-1marker.png";
import cosmeticmarker from "../../assets/images/cosmeticsmarker.png";
import sportsmarker from "../../assets/images/sportstore-1marker.png";
import carrentmarker from "../../assets/images/car_rental-1marker.png";
import clubmarker from "../../assets/images/nightclub-1marker.png";
import gasmarker from "../../assets/images/gasmarker.png";
import librarymarker from "../../assets/images/library-1marker.png";
import leftarrow from "../../assets/images/left.png";
import rightarrow from "../../assets/images/right.png";

import "./amenity.scss";
import { amenityUrl } from "../../urls/urls";
import Amenitydata from "./Amenitydata";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMapContext } from "../context/Mapcontext";
import Events from "../events/eventbox/Events";
import Heritagewalk from "../heritageWalk/Heritagewalk";

const amenities = [
  {
    label: "Toilet",
    name: "toilet",
    image: toilet,
    icon: toileticon,
    whiteicon: twhite,
    marker: tmark,
  },
  {
    label: "Banks",
    name: "bank",
    image: bank,
    icon: bankicon,
    whiteicon: bwhite,
    marker: bmark,
  },
  {
    label: "Atms",
    name: "atm",
    image: atm,
    icon: atmicon,
    whiteicon: awhite,
    marker: amark,
  },
  {
    label: "Restaurants",
    name: "restaurant",
    image: restaurant,
    icon: restroicon,
    whiteicon: rwhite,
    marker: rmark,
  },
  {
    label: "Hotels",
    name: "hotel",
    image: hotel,
    icon: stayicon,
    whiteicon: staywhite,
    marker: lmarker,
  },
  {
    label: "Hospitals",
    name: "hospital",
    image: hospital,
    icon: clinicicon,
    whiteicon: hwhite,
    marker: hmark,
  },
  {
    label: "Bar",
    name: "bar",
    image: bar,
    icon: baricon,
    marker: barmarker,
  },
  {
    label: "Parks",
    name: "park",
    image: park,
    icon: park_icon,
    marker: pmarker,
  },
  {
    label: "Attractions",
    name: "attraction",
    image: attraction,
    icon: attracticon,
    marker: attractionmarker,
  },
  {
    label: "Museums",
    name: "museum",
    image: museum,
    icon: mueseumicon,
    marker: museummarker,
  },
  {
    label: "Libraries",
    name: "library",
    image: library,
    icon: librariesicon,
    marker: librarymarker,
  },
  {
    label: "Pharmacies",
    name: "pharmacy",
    image: drugs,
    icon: pharmicon,
    marker: pharmacymarker,
  },
  {
    label: "Clinics",
    name: "clinic",
    image: clinic,
    icon: clinicon,
    marker: cmarker,
  },
  {
    label: "Theatres",
    name: "theatre",
    image: theatre,
    icon: theatreicon,
    marker: moviesmarker,
  },
  {
    label: "Laundry",
    name: "laundry",
    // image: laundry,
    icon: laundryicon,
    marker: laundrymarker,
  },
  {
    label: "Hairdresser",
    name: "hairdresser",
    image: hairdressing,
    icon: salonicon,
    marker: hairmarker,
  },
  {
    label: "Car wash",
    name: "car_wash",
    image: carwash,
    icon: carwashicon,
    marker: carmarker,
  },
  {
    label: "Beauty Shops",
    name: "beauty_shop",
    image: beautyshop,
    icon: parlouricon,
    marker: cosmeticmarker,
  },
  {
    label: "Sport shops",
    name: "sports_shop",
    image: store,
    icon: sportsicon,
    marker: sportsmarker,
  },

  {
    label: "Car Rental",
    name: "car_rental",
    image: carrental,
    icon: carrenticon,
    marker: carrentmarker,
  },
  {
    label: "Cafes",
    name: "cafe",
    image: cafe,
    icon: cafeicon,
    marker: cmark,
  },
  {
    label: "Bakery",
    name: "bakery",
    image: cook,
    icon: bakeryicon,
    marker: bakerymarker,
  },
  {
    label: "Nightclubs",
    name: "nightclub",
    image: nightclub,
    icon: nightclubicon,
    marker: clubmarker,
  },
  {
    label: "Butcher",
    name: "butcher",
    image: meat,
    icon: meaticon,
    marker: butchermarker,
  },
  // {
  //   label: "Esewa",
  //   name: "esewa",
  //   image: "url_to_esewa_image.jpg",
  //   icon: esewaicon,
  // },
  // {
  //   label: "Cinemas",
  //   name: "cinema",
  //   image: cinema,
  //   icon: cinemaicon,
  // },
  {
    label: "Parking",
    name: "parking",
    image: parking,
    icon: parkicon,
    marker: parkingmarker,
  },
  {
    label: "Heritage",
    name: "heritage",
    image: mueseumicon,
    marker: museummarker,
  },
  {
    label: "Fuels",
    name: "fuel",
    image: fuel,
    icon: fuelicon,
    marker: gasmarker,
  },
];

const Amenity = () => {
  const [minLatBound, setMinLatBound] = useState();
  const [minLngBound, setMinLngBound] = useState();
  const [maxLatBound, setMaxLatBound] = useState();
  const [maxLngBound, setMaxLngBound] = useState();
  const [amenity, setAmenity] = useState();
  const [amenityData, setAmenityData] = useState([]);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [moreAmenities, setMoreAmenities] = useState(false);
  const { isBooleanValue, setBooleanValue } = useMapContext();
  const {
    amenityWord,
    setAmenityValue,
    setIsPointClickedValue,
    isPointClicked,
    direction,
    setDirectionValue,
    clickedIndex,
    setClickedIndexValue,
    // isZooming,
    // setIsZoomingValue,
  } = useMapContext();
  const [loading, setLoading] = useState(false);
  const { current: map } = useMap();
  const [isZooming, setIsZooming] = useState(false); // State to track zooming
  const [indexNum, setIndexNum] = useState(null);
  const [popupInfo, setPopupInfo] = useState(true);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!map) return;

    const updateBounds = () => {
      const bounds = map.getBounds();
      const topRight = bounds.getNorthEast();
      const bottomLeft = bounds.getSouthWest();

      if (!isPointClicked) {
        setMinLatBound(bottomLeft.lat);
        setMinLngBound(bottomLeft.lng);
        setMaxLatBound(topRight.lat);
        setMaxLngBound(topRight.lng);
      }
    };

    // Event handlers for zoom and move events
    const handleZoomStart = () => setIsZooming(true);
    const handleZoomEnd = () => {
      setIsZooming(false);
      updateBounds();
    };

    map.on("movestart", handleZoomStart);
    map.on("moveend", handleZoomEnd);

    // Initial bounds update
    updateBounds();

    // Cleanup event listeners on component unmount
    return () => {
      map.off("moveend", handleZoomEnd);
      map.off("movestart", handleZoomStart);
    };
  }, [map, isPointClicked]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  useEffect(() => {
    const searchAmenity = async (url) => {
      try {
        setLoading(true);
        const response = await fetch(url);
        const geojson = await response.json();
        if (geojson.success) {
          setLoading(false);
          setAmenityData(geojson.data);
          // setBooleanValue(!isBooleanValue);
        } else {
          console.log("failed to fetch");
        }
      } catch (error) {
        console.error("failed to fetch amenity", error);
      }
    };
    if (
      minLatBound !== undefined &&
      minLngBound !== undefined &&
      maxLatBound !== undefined &&
      maxLngBound !== undefined &&
      amenity !== undefined
    ) {
      const url = `${amenityUrl}&minlatbound=${minLatBound}&minlngbound=${minLngBound}&maxlatbound=${maxLatBound}&maxlngbound=${maxLngBound}&amenity=${amenity}`;
      searchAmenity(url);
    }
  }, [minLatBound, minLngBound, maxLatBound, maxLngBound, amenity]);

  const [amenityList, setAmenityList] = useState(false);

  const handleAmenityClick = (clickedAmenity, amenityValue) => {
    if (clickedAmenity) {
      setAmenity(clickedAmenity);
      setAmenityList(true);
      setAmenityValue(amenityValue);
      if (isBooleanValue) {
        setBooleanValue(isBooleanValue);
      } else {
        setBooleanValue(!isBooleanValue);
      }
      if (isPointClicked) {
        setIsPointClickedValue(false);
      }
      setSelectedAmenity(clickedAmenity); // Update the selected amenity state
      setMoreAmenities(false);
    }
    if (clickedAmenity === selectedAmenity) {
      // If the clicked amenity is the same as the existing selected amenity, clear the list
      setSelectedAmenity(null);
      setBooleanValue(!isBooleanValue);
      setAmenityList(false);
      setAmenityData([]);
      setLoading(false);
      setAmenityValue("");
      if (isPointClicked) {
        setIsPointClickedValue(false);
      }
    }
    if (clickedAmenity !== selectedAmenity && clickedAmenity && amenityList) {
      setAmenity(clickedAmenity);
      setAmenityList(true);
      setAmenityValue(amenityValue);
      setBooleanValue(isBooleanValue);
      setSelectedAmenity(clickedAmenity); // Update the selected amenity state
      setMoreAmenities(false);
      if (isPointClicked) {
        setIsPointClickedValue(false);
      }
    }
    // if (amenityData.length > 0 && clickedAmenity && amenityList) {
    //   setLoading(false);
    //   setMoreAmenities(false);
    //   setAmenity();
    //   setAmenityValue("");
    //   setAmenityList(false);
    //   setSelectedAmenity(null);
    //   setAmenityData([]);
    //   setBooleanValue(!isBooleanValue);
    // }
    if (moreAmenities && clickedAmenity) {
      setLoading(false);
      setMoreAmenities(false);
      setBooleanValue(isBooleanValue);
      if (isPointClicked) {
        setIsPointClickedValue(false);
      }
    }
    // if (moreAmenities && clickedAmenity && amenityList) {
    //   setMoreAmenities(true);
    //   setBooleanValue(!isBooleanValue);
    //   setAmenityValue("Amenities");
    //   setAmenityList(false);
    // }
  };

  useEffect(() => {
    if (amenityWord === "") {
      setAmenity();
      setAmenityList(false);
      setSelectedAmenity(null);
      setAmenityData([]);
      setMoreAmenities(false);
    }

    // if (direction) {
    //   setAmenity();
    //   setAmenityList(false);
    //   setSelectedAmenity(null);
    //   setAmenityData([]);
    //   setMoreAmenities(false);
    // }
  }, [
    amenityWord,
    isBooleanValue,
    moreAmenities,
    setBooleanValue,
    selectedAmenity,
    amenity,
    isPointClicked,
  ]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      // slidesToSlide: 8, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      // slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      // slidesToSlide: 3, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group" style={{ position: "absolute" }}>
        <Button
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => previous()}
        >
          <img src={left} alt="leftarrow" />
        </Button>
        <Button onClick={() => next()}>
          <img src={right} alt="rightarrow" />
        </Button>
      </div>
    );
  };

  const handleMoreAmenities = () => {
    if (moreAmenities && isBooleanValue) {
      setMoreAmenities(false);
      setBooleanValue(!isBooleanValue);
      setAmenityValue("");
    }
    if (!moreAmenities) {
      setMoreAmenities(true);
      setBooleanValue(!isBooleanValue);
      setAmenityValue("Amenities");
    }
    if (selectedAmenity && amenityList) {
      setMoreAmenities(true);
      setAmenityValue("Amenities");
      setBooleanValue(isBooleanValue);
      setAmenityList(false);
      setSelectedAmenity(null);
      setAmenityData([]);
    }
    if (!moreAmenities && isBooleanValue) {
      // setDirectionValue(false);
      setBooleanValue(isBooleanValue);
    }
  };

  useEffect(() => {
    // Set indexNum when amenityData changes
    if (amenityData) {
      const newIndexNum = amenityData.map((_, index) => index);
      setIndexNum(newIndexNum);
    }
  }, [amenityData]);

  const featuress = amenityData?.map((point, index) => {
    const matchingAmenity = amenities.find(
      (amenity) => amenity.name === selectedAmenity
    );
    // console.log(matchingAmenity.marker);
    return {
      type: "Feature",
      properties: {
        index: indexNum[index], // Use the indexNum state
        name: point.name,
        road: point.road,
        place: point.place,
        municipality: point.municipality,
        amenityType: selectedAmenity,
        marker: matchingAmenity ? matchingAmenity.marker : null, // Assign marker from amenities if found, otherwise null
      },
      geometry: {
        type: "Point",
        coordinates: point.coords,
      },
    };
  });

  return (
    <>
      <div className="scroll-container">
        <div className="top" ref={scrollContainerRef}>
          <Events />
          <Heritagewalk />
          {amenities.slice(0, 3).map((amenity, index) => (
            <div className="list" key={index}>
              <Button
                style={{
                  backgroundColor:
                    selectedAmenity === amenity.name ? "#E37547" : "",
                }}
                onClick={() => handleAmenityClick(amenity.name, amenity.label)}
              >
                <img
                  src={
                    selectedAmenity === amenity.name
                      ? amenity.whiteicon
                      : amenity.image
                  }
                  alt={amenity.name}
                />
                <span
                  style={{
                    color: selectedAmenity === amenity.name ? "#ffff" : "",
                  }}
                >
                  {amenity.name}
                </span>
              </Button>
            </div>
          ))}
          <div className="list">
            <Button onClick={handleMoreAmenities}>
              <img src={more} alt="show-more" />
              <span>Show More</span>
            </Button>
          </div>
        </div>
        <div className="scroll-arrow left" onClick={scrollLeft}>
          <img
            src={leftarrow}
            alt="left"
            style={{ height: "20px", width: "20px" }}
          />
          {/* &lt; */}
        </div>
        <div className="scroll-arrow right" onClick={scrollRight}>
          <img
            src={rightarrow}
            alt="right"
            style={{ height: "20px", width: "20px" }}
          />
          {/* &gt; */}
        </div>
      </div>

      {moreAmenities && (
        <div className="more-amenities" fluid="true">
          <div className="more-amenity-header">
            <span>All Categories</span>
          </div>
          <Row className="list">
            {amenities.map((data, index) => (
              <Col xs={3} sm={3} md={3} key={index} className="more-list">
                <Button
                  onClick={() => {
                    handleAmenityClick(data.name, data.label);
                  }}
                >
                  <img
                    src={data.icon ? data.icon : data.image}
                    alt={data.name}
                  />
                  <br />
                  <span>{data.label}</span>
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      )}

      {loading ? (
        <div className="amenities" fluid="true">
          <div className="loading-screen">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : amenityData.length > 0 && selectedAmenity && amenityList ? (
        <div
          className={direction ? "amenities no-scroll" : "amenities"}
          fluid="true"
          // style={{ display: direction ? "none" : "block" }}
        >
          <Amenitydata
            selectedAmenity={selectedAmenity}
            amenityData={amenityData}
            isZooming={!isZooming}
          />
        </div>
      ) : selectedAmenity && amenityList ? (
        <div className="amenities" fluid="true">
          <h4>No results to show</h4>
        </div>
      ) : null}

      {/* Render the map component here with amenityData */}
      <div className="amenity-points">
        {featuress?.map((feature, index) => (
          <Marker
            key={index}
            longitude={feature.geometry.coordinates[0]}
            latitude={feature.geometry.coordinates[1]}
            anchor="bottom"
          >
            {index === clickedIndex ? (
              <img
                src={feature.properties.marker}
                alt="mark"
                className="marker-img"
                style={{ height: "45px", width: "45px" }} // Larger size for clicked marker
              />
            ) : (
              <div className="text-center">
                <img
                  src={feature.properties.marker}
                  alt="mark"
                  className="marker-img"
                  style={{ height: "32px", width: "32px" }} // Default size for other markers
                />
                <br />
                {/* <div>
                  <span>{feature.properties.name}</span>
                </div> */}
              </div>
            )}
          </Marker>
        ))}
      </div>
    </>
  );
};

export default Amenity;
