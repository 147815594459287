import React, { useEffect, useState } from "react";
import "./panoromaview.scss";
import { panoViewUrl } from "../../urls/urls";
import cross from "../../assets/images/crossbtn.png";
import { useMapContext } from "../context/Mapcontext";
import Panoromic from "./Panoromic";
import Map from "react-map-gl/maplibre";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import mapDirection from "../../assets/images/currentlocationarrow.png";

const Panoromaview = ({ latitude, longitude }) => {
  const [viewImage, setViewImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const zoom = 20;
  const { latPoints, longPoints, yawData, pitchData, setYawDataValue } =
    useMapContext(); // Use the context values
  const { isBooleanValue, setBooleanValue } = useMapContext();

  const [mapDirectionAdded, setMapDirectionAdded] = useState(false);

  // console.log(yawData, pitchData);

  useEffect(() => {
    const panoImage = async (url) => {
      setLoading(true);
      try {
        const response = await fetch(url);
        const geojson = await response.json();
        if (geojson.success) {
          setViewImage(geojson.data);
        } else {
          console.log("failed to fetch panoimage");
          setViewImage(null); // Set to null or any default value
        }
      } catch (error) {
        console.error("Failed to fetch panoimage", error);
        setViewImage(null); // Set to null or any default value
      } finally {
        setLoading(false);
      }
    };
    if (latPoints !== null && longPoints !== null) {
      const url = `${panoViewUrl}${latPoints},${longPoints}/${zoom}?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
      panoImage(url);
    }
    if (latitude !== null && longitude !== null) {
      const url = `${panoViewUrl}${latitude},${longitude}/${zoom}?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
      panoImage(url);
    }
    if (
      latitude === null &&
      longitude === null &&
      latPoints === null &&
      longPoints === null
    ) {
      const defaultUrl = `${panoViewUrl}27.677106,85.32239/${zoom}?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
      panoImage(defaultUrl);
    }
  }, [latPoints, latitude, longPoints, longitude]);

  const handlePanoClick = (pitch, yaw) => {
    setYawDataValue(yaw); // Update yawData in context
    <>
      <div className="selected-pano">
        {viewImage ? (
          <Panoromic imagePath={viewImage.imgurl} id={viewImage.image} />
        ) : (
          <div className="no-pano">
            <p>Image couldn't be found.</p>
          </div>
        )}
      </div>
      ;
    </>;
  };

  const handleClick = () => {
    const element = document.querySelector(".select, .pano");
    if (element) {
      if (!element.classList.contains("select")) {
        element.classList.add("clicked-class");
      }

      const imgClickHandler = () => {
        element.classList.remove("clicked-class");
        element.removeChild(img);
        img.removeEventListener("click", imgClickHandler);
      };

      const img = document.createElement("img");
      img.src = cross;
      img.style.width = "30px";
      img.style.height = "30px";
      img.style.position = "absolute";
      img.style.top = "10px";
      img.style.right = "17px";
      img.style.zIndex = "1000";

      // Add event listener to the img element
      img.addEventListener("click", imgClickHandler);

      // Add img to element if it doesn't have select class
      if (!element.classList.contains("select")) {
        element.appendChild(img);
      }
    }
  };

  return (
    <div className={isBooleanValue ? "select" : "pano"}>
      {/*  */}
      {viewImage && (
        <Panoromic
          imagePath={viewImage.imgurl}
          uniqueId={viewImage.image}
          onPanoClick={handlePanoClick}
          lng={longPoints}
          lat={latPoints}
        />
      )}
    </div>
  );
};

export default Panoromaview;
