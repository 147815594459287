import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  autocompleteUrl,
  imageUrl,
  routingUrl,
  searchFromCurrentLocationUrl,
  searchUrl,
} from "../../urls/urls";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import join from "../../assets/images/joinroute.png";
import walk from "../../assets/images/walk.png";
import cycle from "../../assets/images/bike.png";
import taxi from "../../assets/images/taxi-stop.png";
import circle from "../../assets/images/circle.png";
import locate from "../../assets/images/currentlocation.png";
import reverseIcon from "../../assets/images/reversearrow.png";
import location from "../../assets/images/greypin.png";
import pin from "../../assets/images/startingPoint.png";
import dottedline from "../../assets/images/routeline.png";
import motorbike from "../../assets/images/biking.png";
import destination from "../../assets/images/destinationMarker.png";
import stopPin from "../../assets/images/placepin.png";
import "./direction.scss";
import { Layer, Marker, Source } from "react-map-gl/maplibre";
import { Link } from "react-router-dom";
import { autoCompleteFetchDatas, searchCurrentLocation } from "../apis/api";

import { useMap } from "react-map-gl/maplibre";
import close from "../../assets/images/close.png";
import { useMapContext } from "../context/Mapcontext";

const Direction = ({
  firstLat,
  firstLng,
  secondLat,
  secondLng,
  start,
  stop,
}) => {
  const [route, setRoute] = useState([]);
  const [srcLat, setSrcLat] = useState(firstLat);
  const [srcLng, setSrcLng] = useState(firstLng);
  const [dstLat, setDstLat] = useState(secondLat);
  const [dstLng, setDstLng] = useState(secondLng);
  const [data, setData] = useState([]);
  const [startWord, setStartWord] = useState("");
  const [stopWord, setStopWord] = useState("");
  const [routeDuration, setRouteDuration] = useState();
  const [isStartTextActive, setIsStartTextActive] = useState(false);
  const [isStopTextActive, setIsStopTextActive] = useState(false);
  const [startSelected, setStartSelected] = useState(false);
  const [stopSelected, setStopSelected] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [startInput, setStartInput] = useState("");
  const [stopInput, setStopInput] = useState("");
  const [startPlace, setStartPlace] = useState();
  const [stopPlace, setStopPlace] = useState();
  const [isSourceActive, setIsSourceActive] = useState(false);
  const [isDestinationActive, setIsDestinationActive] = useState(false);
  const { isZooming, setIsZoomingValue } = useMapContext();

  const { current: map } = useMap();

  const [begin, setBegin] = useState(start || "");
  const [end, setEnd] = useState(stop || "");

  useEffect(() => {
    // Update srcLat and srcLng if firstLat and firstLng change
    setSrcLat(firstLat);
    setSrcLng(firstLng);
  }, [firstLat, firstLng]);

  useEffect(() => {
    // Update dstLat and dstLng if secondLat and secondLng change
    setDstLat(secondLat);
    setDstLng(secondLng);
    // console.log(dstLat, dstLng, stop);
  }, [secondLat, secondLng]);

  // useEffect to update state if props change
  useEffect(() => {
    setBegin(start || null);
  }, [start]);

  useEffect(() => {
    setEnd(stop || null);
  }, [stop]);

  const lineStyle = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      // "line-color": "rgba(0,255,0,0.5)",
      "line-color": "#E37547",
      "line-width": 8,
    },
  };

  //polygon style
  const polygonStyle = {
    id: "maine",
    type: "fill",
    source: "maine",
    layout: {},
    paint: {
      // "fill-color": "#a1d1a7",
      "fill-color": "#a0d097",
      "fill-opacity": 0.6,
    },
  };

  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  }, []); // Run this effect only once when the component mounts

  const autoCompleteFetchData = async (textWord) => {
    if (latitude !== null && longitude !== null) {
      let datas = await autoCompleteFetchDatas(textWord, latitude, longitude);
      setData(datas);
    }
    if (latitude === null && longitude === null) {
      let datas = await autoCompleteFetchDatas(textWord, 27.677106, 85.32239);
      setData(datas);
    }
    // else {
    //   console.error("Latitude and longitude not available.");
    // }
  };

  const searchStartLocation = async (url) => {
    try {
      const response = await fetch(url);
      const geojson = await response.json();

      if (geojson.success) {
        setStartPlace(geojson.data);
        const features = geojson.data.features.map((feature) => {
          const coordinates = feature.geometry.coordinates;
          let formattedFeature = null;

          if (feature.geometry.type === "Point") {
            // Handle Point geometry
            const [lng, lat] = coordinates;
            // settingZoomLevels(lng, lat, 18);
            setSrcLng(lng);
            setSrcLat(lat);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [lng, lat],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "Polygon") {
            // Handle Polygon geometry
            const polygonCoordinates = coordinates[0]; // Assuming the first set of coordinates represents the outer ring of the polygon
            // settingZoomLevels(
            //   polygonCoordinates[0][0],
            //   polygonCoordinates[0][1],
            //   18
            // ); // Assuming the first point of the polygon for setting zoom
            setSrcLng(polygonCoordinates[0][0]);
            setSrcLat(polygonCoordinates[0][1]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [polygonCoordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "MultiPolygon") {
            // Handle MultiPolygon geometry
            const multiPolygonCoordinates = coordinates.map(
              (polygon) => polygon[0][0]
            ); // Assuming the first point of the first polygon for setting zoom

            // settingZoomLevels(
            //   multiPolygonCoordinates[0][0],
            //   multiPolygonCoordinates[0][1],
            //   18
            // ); // Assuming the first point of the first polygon for setting zoom
            setSrcLng(multiPolygonCoordinates[0][0]);
            setSrcLat(multiPolygonCoordinates[0][1]);
            // console.log(multiPolygonCoordinates[0][0]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "MultiPolygon",
                coordinates: [multiPolygonCoordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "LineString") {
            // Handle LineString geometry
            // settingZoomLevels(coordinates[0][0], coordinates[0][1], 13); // Assuming the first point of the LineString for setting zoom
            setSrcLng(coordinates[0][0]);
            setSrcLat(coordinates[0][1]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [coordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "MultiLineString") {
            // Handle MultiLineString geometry
            const multiLineStringCoordinates = coordinates.map(
              (lineString) => lineString[0]
            );
            // settingZoomLevels(
            //   multiLineStringCoordinates[0][0],
            //   multiLineStringCoordinates[0][1],
            //   16
            // ); // Assuming the first point of the first LineString for setting zoom
            setSrcLat(multiLineStringCoordinates[0][1]);
            setSrcLng(multiLineStringCoordinates[0][0]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "MultiLineString",
                coordinates: [multiLineStringCoordinates],
              },
              properties: feature.properties,
            };
          }

          return formattedFeature;
        });

        return {
          type: "FeatureCollection",
          features: features.filter((feature) => feature !== null),
        };
      } else {
        console.log("failed to fetch data");
      }
    } catch (error) {
      console.error("failed to fetch", error);
    }
  };

  const searchStopLocation = async (uri) => {
    try {
      const response = await fetch(uri);
      const geojson = await response.json();
      if (geojson.success) {
        setStopPlace(geojson.data);
        const features = geojson.data.features.map((feature) => {
          const coordinates = feature.geometry.coordinates;
          let formattedFeature = null;

          if (feature.geometry.type === "Point") {
            // Handle Point geometry
            const [lng, lat] = coordinates;
            // settingZoomLevels(lng, lat, 18);
            setDstLng(lng);
            setDstLat(lat);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [lng, lat],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "Polygon") {
            // Handle Polygon geometry
            const polygonCoordinates = coordinates[0]; // Assuming the first set of coordinates represents the outer ring of the polygon
            // settingZoomLevels(
            //   polygonCoordinates[0][0],
            //   polygonCoordinates[0][1],
            //   18
            // ); // Assuming the first point of the polygon for setting zoom
            setDstLng(polygonCoordinates[0][0]);
            setDstLat(polygonCoordinates[0][1]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [polygonCoordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "MultiPolygon") {
            // Handle MultiPolygon geometry
            const multiPolygonCoordinates = coordinates.map(
              (polygon) => polygon[0][0]
            ); // Assuming the first point of the first polygon for setting zoom

            // settingZoomLevels(
            //   multiPolygonCoordinates[0][0],
            //   multiPolygonCoordinates[0][1],
            //   18
            // ); // Assuming the first point of the first polygon for setting zoom
            setDstLng(multiPolygonCoordinates[0][0]);
            setDstLat(multiPolygonCoordinates[0][1]);
            // console.log(multiPolygonCoordinates[0][0]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "MultiPolygon",
                coordinates: [multiPolygonCoordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "LineString") {
            // Handle LineString geometry
            // settingZoomLevels(coordinates[0][0], coordinates[0][1], 13); // Assuming the first point of the LineString for setting zoom
            setDstLng(coordinates[0][0]);
            setDstLat(coordinates[0][1]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [coordinates],
              },
              properties: feature.properties,
            };
          } else if (feature.geometry.type === "MultiLineString") {
            // Handle MultiLineString geometry
            const multiLineStringCoordinates = coordinates.map(
              (lineString) => lineString[0]
            );
            // settingZoomLevels(
            //   multiLineStringCoordinates[0][0],
            //   multiLineStringCoordinates[0][1],
            //   16
            // ); // Assuming the first point of the first LineString for setting zoom
            setDstLat(multiLineStringCoordinates[0][1]);
            setDstLng(multiLineStringCoordinates[0][0]);
            formattedFeature = {
              type: "Feature",
              geometry: {
                type: "MultiLineString",
                coordinates: [multiLineStringCoordinates],
              },
              properties: feature.properties,
            };
          }

          return formattedFeature;
        });

        return {
          type: "FeatureCollection",
          features: features.filter((feature) => feature !== null),
        };
      } else {
        console.log("Failed to fetch");
      }
    } catch (error) {
      console.error("Error fetching stop location:", error);
    }
  };

  const [locationsSelected, setLocationsSelected] = useState(false);

  const [selectedMode, setSelectedMode] = useState("walking");

  const handleModeClick = (mode) => {
    setSelectedMode(mode);
  };

  // Memoize the 'modes' array using useMemo

  const modes = useMemo(
    () => [
      "walking",
      "bike",
      "cycling",
      "driving",
      // "galliDriving",
    ],
    []
  );

  const sourceId = `route-${selectedMode}`;
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (startWord) {
      setStartSelected(true);
      // console.log(startSelected);
    }
    if (stopWord) {
      setStopSelected(true);
    }
    // Check if both startWord and stopWord have values and update locationsSelected
    if (startWord && stopWord) {
      setLocationsSelected(true);
    }
    if (srcLat && srcLng && dstLat && dstLng) {
      const fetchRoute = async () => {
        const promises = modes.map(async (currentMode) => {
          const url = `${routingUrl}mode=${currentMode}&srcLat=${srcLat}&srcLng=${srcLng}&dstLat=${dstLat}&dstLng=${dstLng}&accessToken=${process.env.REACT_APP_TOKEN_KEY}`;

          try {
            setLoad(true);
            const response = await fetch(url);
            const geojson = await response.json();
            if (geojson.success) {
              setLoad(false);
              setRoute(geojson.data);
              return { mode: currentMode, data: geojson.data };
            } else {
              console.log(`Failed to fetch data for ${currentMode} mode`);
              return { mode: currentMode, error: "Failed to fetch data" };
            }
          } catch (error) {
            console.error(
              `Error fetching data for ${currentMode} mode:`,
              error
            );
            return { mode: currentMode, error };
          }
        });

        const results = await Promise.allSettled(promises);
        setRouteDuration(results);
        // console.log(results);
      };
      // Check if both start and stop locations have been selected

      if (locationsSelected) {
        fetchRoute();
      } else if (firstLat && firstLng && secondLat && secondLng) {
        fetchRoute();
      } else if (startSelected && secondLat && secondLng) {
        fetchRoute();
      } else if (stopSelected && firstLat && firstLng) {
        fetchRoute();
      }
    }
  }, [
    srcLat,
    srcLng,
    dstLat,
    dstLng,
    startWord,
    stopWord,
    locationsSelected,
    firstLat,
    firstLng,
    secondLat,
    secondLng,
    modes,
    startSelected,
    stopSelected,
  ]); // Dependencies array remains the same

  useEffect(() => {
    if (routeDuration && selectedMode) {
      if (load) {
        setSelectedRoute(null); // Reset selectedRoute to null when data is loading
      } else {
        const selectedRouteData = routeDuration.find(
          (data) => data.value.mode === selectedMode
        );

        if (selectedRouteData) {
          setSelectedRoute(selectedRouteData.value.data);
        } else {
          console.log(`Route data not found for mode: ${selectedMode}`);
          setSelectedRoute(null);
        }
      }
    }
  }, [load, routeDuration, selectedMode]);

  function secondsToTime(sec) {
    const h = Math.floor(sec / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((sec % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(sec % 60)
        .toString()
        .padStart(2, "0");

    // Remove leading zero if the hour or minute is a single digit
    const formattedH = h !== "00" ? h.replace(/^0+/, "") : h;
    const formattedM = m !== "00" ? m.replace(/^0+/, "") : m;

    let result = "";

    if (formattedH !== "00") {
      result += formattedH + " hr";
      if (formattedM !== "00") {
        result += " " + formattedM + " min";
      }
    } else if (formattedM !== "00") {
      result += formattedM + " min";
    } else {
      // Only include seconds if there are no hours or minutes
      result += s + " sec";
    }

    return result;
  }

  const handleName = async (name) => {
    if (isStartTextActive) {
      setStartWord(name);
      setStartInput(name);
      const encodedStartName = encodeURIComponent(name);
      if (encodedStartName) {
        const url = `${searchUrl}&name=${encodedStartName}`;
        await searchStartLocation(url);
      }
    }
    if (isStopTextActive) {
      setStopInput(name);
      setStopWord(name);
      const encodedStopName = encodeURIComponent(name);
      if (encodedStopName) {
        const uri = `${searchUrl}&name=${encodedStopName}`;
        await searchStopLocation(uri);
      }
    }
    // Reset input field activation status
    setIsStartTextActive(false);
    setIsStopTextActive(false);

    setData(null);
  };

  const handleLngLat = () => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          const { latitude, longitude } = position.coords;
          if (isSourceActive) {
            setSrcLat(latitude);
            setSrcLng(longitude);
            setStartWord("Your Location");
            // console.log("src", latitude, longitude);
          }
          if (isDestinationActive) {
            setDstLat(latitude);
            setDstLng(longitude);
            setStopWord("Your Location");
            // console.log("dst", latitude, longitude);
          }
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error);
          if (error.code === error.PERMISSION_DENIED) {
            // Permission denied, inform the user to turn on their location settings
            alert(
              "Please turn on your location settings at the top right corner of search bar."
            );
          }
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  };

  //for fitbounds options
  // Check if route has data
  useEffect(() => {
    if (route && route.data && route.data[0] && route.data[0].latlngs) {
      const coordinates = route.data[0].latlngs;

      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

      map.fitBounds(bounds, {
        padding: 20,
        // padding: { top: 50, bottom: 30, left: 30, right: 20 },
      });
    }
  }, [route, map]);

  const handleClose = () => {
    setRoute([]);
    setStartWord("");
    setStopWord("");
    setRouteDuration();
  };

  // console.log(start);

  const handleInputClick = (e) => {
    // Select the value of the input field
    e.target.select();
  };

  const img = new Image();
  img.src = join;
  img.onload = () => {
    // Check if the image with the name "custom-marker" already exists
    if (!map.hasImage("custom-marker")) {
      map.addImage("custom-marker", img);
    }
  };

  // Define the style for the line
  const lineCoords = {
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#E37547",
      "line-width": 8,
      "line-pattern": "custom-marker",
    },
  };

  // Merge the coordinates from startPlace, stopPlace, and selectedRoute to create a LineString
  const combinedCoordinates = [];
  const startPlaceCombined = [];

  if (stopPlace && selectedRoute) {
    const stopPlaceCoordinates = [dstLng, dstLat];
    const selectedRouteCoordinates = selectedRoute?.data[0]?.latlngs;
    const num = selectedRouteCoordinates?.length - 1;
    const lastCoordinates = selectedRouteCoordinates[num];
    if (stopPlaceCoordinates && lastCoordinates) {
      combinedCoordinates.push([...lastCoordinates], [...stopPlaceCoordinates]);
    }
  }

  if (secondLat && secondLng && selectedRoute) {
    const endCoordinates = [secondLng, secondLat];
    const selectedRouteCoordinates = selectedRoute?.data[0]?.latlngs;
    const num = selectedRouteCoordinates?.length - 1;
    const lastCoordinates = selectedRouteCoordinates[num];
    if (endCoordinates && lastCoordinates) {
      if (!reverse) {
        combinedCoordinates.push([...lastCoordinates], [...endCoordinates]);
      } else if (reverse && load) {
        combinedCoordinates.push([]);
      }
    }
  }

  if (startPlace && selectedRoute) {
    const startPlaceCoordinates = [srcLng, srcLat];
    const selectedRouteCoordinates = selectedRoute?.data[0]?.latlngs;
    const firstCoordinates = selectedRouteCoordinates[0];
    if (startPlaceCoordinates && selectedRouteCoordinates) {
      startPlaceCombined.push(
        [...firstCoordinates],
        [...startPlaceCoordinates]
      );
    }
  }

  const handleReverse = () => {
    // Swap source and destination coordinates
    const newSrcLat = dstLat;
    const newSrcLng = dstLng;
    const newDstLat = srcLat;
    const newDstLng = srcLng;

    setReverse(true);
    // Set the new coordinates
    setSrcLat(newSrcLat);
    setSrcLng(newSrcLng);
    setDstLat(newDstLat);
    setDstLng(newDstLng);

    setStartWord(stopWord);
    setStopWord(startWord);
    setStartInput(stopInput);
    setStopInput(startInput);
    setBegin(end);
    setEnd(begin);
  };

  return (
    <div className="route-direction" fluid="true">
      <div className="directions">
        <div className="icons">
          <Col
            sm={3}
            // md={3}
            // lg={3}
            className="route-duration"
            onClick={() => handleModeClick("walking")}
          >
            <div className={selectedMode === "walking" ? "selected" : ""}>
              <img src={walk} alt="walk" />
            </div>

            <br />
          </Col>
          <Col
            sm={3}
            // md={3}
            // lg={3}
            className="route-duration"
            onClick={() => handleModeClick("bike")}
          >
            <div className={selectedMode === "bike" ? "selected" : ""}>
              <img src={motorbike} alt="motorbike" />
            </div>

            <br />
          </Col>
          <Col
            sm={3}
            // md={3}
            // lg={3}
            className="route-duration"
            onClick={() => handleModeClick("cycling")}
          >
            <div className={selectedMode === "cycling" ? "selected" : ""}>
              <img src={cycle} alt="cycle" />
            </div>

            <br />
          </Col>
          <Col
            sm={3}
            // md={3}
            // lg={3}
            className="route-duration"
            onClick={() => handleModeClick("driving")}
          >
            <div className={selectedMode === "driving" ? "selected" : ""}>
              <img src={taxi} alt="taxi" />
            </div>

            <br />
          </Col>

          {/* <div className="close">
            <img src={close} alt="close" onClick={handleClose} />
          </div> */}
        </div>
        <div className="icons" style={{ paddingBottom: "23px" }}>
          <Col md={3} className="route-duration">
            <span>
              {routeDuration &&
                routeDuration[0]?.value?.data?.data[0] &&
                secondsToTime(routeDuration[0].value.data.data[0].duration)}
            </span>
          </Col>
          <Col md={3} className="route-duration">
            <span>
              {routeDuration &&
                routeDuration[1]?.value?.data?.data[0] &&
                secondsToTime(routeDuration[1].value.data.data[0].duration)}
            </span>
          </Col>
          <Col md={3} className="route-duration">
            <span>
              {routeDuration &&
                routeDuration[2]?.value?.data?.data[0] &&
                secondsToTime(routeDuration[2].value.data.data[0].duration)}
            </span>
          </Col>
          <Col md={3} className="route-duration">
            <span>
              {routeDuration &&
                routeDuration[3]?.value?.data?.data[0] &&
                secondsToTime(routeDuration[3].value.data.data[0].duration)}
            </span>
          </Col>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col md={9}>
            <div className="direction-from">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "12px",
                }}
              >
                <Col md={1}>
                  <img src={circle} alt="circle" />
                </Col>
                <Col md={11} style={{ paddingLeft: "9px" }}>
                  {/* <input
                    type="text"
                    name="startInput"
                    id="start"
                    placeholder="Choose Starting Location"
                    value={startInput || startWord || begin || ""}
                    onChange={(e) => {
                      setIsStartTextActive(true);
                      setIsStopTextActive(false);
                      autoCompleteFetchData(e.target.value);
                      setStartInput(e.target.value);
                      setInputValue(e.target.value);
                    }}
                    onClick={(e) => {
                      handleInputClick(e);
                      setIsSourceActive(true);
                      setIsDestinationActive(false);
                    }}
                    autoComplete="off"
                  /> */}
                  <input
                    type="text"
                    name="startInput"
                    id="start"
                    placeholder="Choose Starting Location"
                    value={startInput || startWord || begin || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Clear related states when the input is empty
                      if (!inputValue) {
                        setStartInput("");
                        setStartWord("");
                        setBegin("");
                        setInputValue("");
                      } else {
                        setIsStartTextActive(true);
                        setIsStopTextActive(false);
                        autoCompleteFetchData(inputValue); // Assuming this fetches data based on input
                        setStartInput(inputValue);
                        setInputValue(inputValue);
                      }
                    }}
                    onClick={(e) => {
                      handleInputClick(e);
                      setIsSourceActive(true);
                      setIsDestinationActive(false);
                    }}
                    autoComplete="off"
                  />
                </Col>
              </div>
            </div>
            <div className="overlay">
              <img src={dottedline} alt="indicator" />
            </div>

            <div className="direction-to">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col md={1}>
                  <img src={destination} alt="circle" />
                </Col>
                <Col md={11} style={{ paddingLeft: "9px" }}>
                  <input
                    type="text"
                    name="stopInput"
                    id="stop"
                    placeholder="Choose Destination"
                    value={stopInput || stopWord || end || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Clear related states when the input is empty
                      if (!inputValue) {
                        setStopInput("");
                        setStopWord("");
                        setEnd("");
                        setInputValue("");
                      } else {
                        setIsStartTextActive(false);
                        setIsStopTextActive(true);
                        autoCompleteFetchData(inputValue); // Assuming this fetches data based on input
                        setStopInput(inputValue);
                        setInputValue(inputValue);
                      }
                    }}
                    onClick={(e) => {
                      handleInputClick(e);
                      setIsDestinationActive(true);
                      setIsSourceActive(false);
                    }}
                    autoComplete="off"
                  />

                  {/* <input
                    type="text"
                    name="stopInput"
                    id="stop"
                    placeholder="Choose Destination"
                    value={stopInput || stopWord || end || ""}
                    onChange={(e) => {
                      setIsStartTextActive(false);
                      setIsStopTextActive(true);
                      autoCompleteFetchData(e.target.value);
                      setStopInput(e.target.value);
                      setInputValue(e.target.value);
                    }}
                    onClick={(e) => {
                      handleInputClick(e);
                      setIsDestinationActive(true);
                      setIsSourceActive(false);
                    }}
                    autoComplete="off"
                  /> */}
                </Col>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="reverse-places">
              <img
                src={reverseIcon}
                style={{
                  height: "22px",
                  width: "22px",
                  position: "relative",
                  left: "74%",
                }}
                alt="reverse"
                onClick={handleReverse}
              />
            </div>
          </Col>
        </div>

        <hr />

        {data && data.length > 0 ? (
          <div className="locationsearch-data">
            <ul>
              {data.slice(0, 5).map((item) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      handleName(item.name);
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        // paddingBottom: "10px",
                      }}
                    >
                      <Col
                        md={2}
                        // style={{
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        // }}
                      >
                        {item.logo ? (
                          <img
                            src={`${imageUrl}${JSON.parse(item.logo)[0].image}`}
                            alt={`${item.name}`}
                          />
                        ) : (
                          <img src={location} alt="logo" />
                        )}
                      </Col>
                      <Col
                        md={10}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>{item.name}</span>
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {selectedRoute ? (
          <div>
            <h4>
              Total Distance :&nbsp;
              {(selectedRoute.data[0].distance / 1000).toFixed(1)} km
            </h4>
          </div>
        ) : (
          <div className="locate" onClick={handleLngLat}>
            <Row
              style={{
                display: "flex",
              }}
            >
              <Col md={2}>
                <img src={locate} alt="circle" />
              </Col>
              <Col md={10} style={{ display: "contents" }}>
                <span>Your location</span>
              </Col>
            </Row>
          </div>
        )}
      </div>

      {srcLat && srcLng && (
        <Marker longitude={srcLng} latitude={srcLat} anchor="bottom">
          <img src={pin} alt="startpin" style={{ height: "30px" }} />
        </Marker>
      )}

      {dstLat && dstLng && (
        <Marker longitude={dstLng} latitude={dstLat} anchor="bottom">
          <img src={stopPin} alt="stoppin" style={{ height: "38px" }} />
        </Marker>
      )}

      {startPlaceCombined?.length > 0 && selectedRoute ? (
        <Source
          key="startroute"
          id="combined-start-route"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: startPlaceCombined,
                },
              },
            ],
          }}
        >
          <Layer {...lineCoords} />
        </Source>
      ) : null}

      {selectedMode && (
        <Source
          key={sourceId}
          id={sourceId}
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: selectedRoute?.data[0].latlngs,
                },
              },
            ],
          }}
        >
          <Layer {...lineStyle} />
        </Source>
      )}

      {combinedCoordinates?.length > 0 && selectedRoute ? (
        <Source
          key="endroute"
          id="combined-end-route"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: combinedCoordinates,
                },
              },
            ],
          }}
        >
          <Layer {...lineCoords} />
        </Source>
      ) : null}
    </div>
  );
};

export default Direction;
