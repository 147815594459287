import React, { useEffect, useState } from "react";
import "./amenitiesinfo.scss";
import { useMapContext } from "../../context/Mapcontext";
import { Button } from "react-bootstrap";
import getdirection from "../../../assets/images/get-direction.png";
import share from "../../../assets/images/sharebtn.png";
import cancel from "../../../assets/images/remove.png";
import Direction from "../../directions/Direction";
import { useMap } from "react-map-gl/maplibre";
import maplibregl from "maplibre-gl";

const Amenitiesinfo = ({ data, amenityName }) => {
  const { isBooleanValue, setBooleanValue } = useMapContext();
  const {
    direction,
    setDirectionValue,
    setIsPointClickedValue,
    isPointClicked,
  } = useMapContext();
  const [srcLat, setSrcLat] = useState(data?.coords[1]);
  const [srcLng, setSrcLng] = useState(data?.coords[0]);
  const [isVisible, setIsVisible] = useState(true);
  const name = data?.name ? data.name : amenityName;
  const { current: map } = useMap();

  // Set srcLat and srcLng only if data is not null
  // useEffect(() => {
  //   // console.log(data);
  //   if (data) {
  //     setSrcLat(data.coords[1]);
  //     setSrcLng(data.coords[0]);
  //     setIsVisible(true); // Ensure visibility when data is present
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (map && direction) {
  //     const bounds = map.getBounds();
  //     const topRight = bounds.getNorthEast();
  //     const bottomLeft = bounds.getSouthWest();

  //     // Perform fitBounds using the obtained bounds
  //     const newBounds = new maplibregl.LngLatBounds(bottomLeft, topRight);
  //     map.fitBounds(newBounds);
  //   }
  // }, [direction, map]);

  const handleDirection = () => {
    // setIsVisible(false);
    // setIsPointClickedValue(false);
    setDirectionValue(!direction);
    if (isBooleanValue) {
      setBooleanValue(isBooleanValue);
    } else {
      setBooleanValue(!isBooleanValue);
    }
  };

  const handleClear = () => {
    setSrcLat(null);
    setSrcLng(null);
    setIsVisible(false); // Hide divs when clearing data
    setIsPointClickedValue(false);
  };

  return (
    <>
      {isVisible &&
        data && ( // Conditional rendering to check if point is not null
          <div className={isBooleanValue ? "pointinfo-select" : "point-info"}>
            <ul>
              <li key={data.name}>
                <div className="point-title">
                  <span>
                    {data.name ? data?.name?.split(",")[0] : amenityName}
                  </span>
                  <img src={cancel} alt="close" onClick={handleClear} />
                </div>
                <span>{data.place}</span>
                <br />
                <span>
                  {data.road
                    ? `${data.road}, ${data.municipality}`
                    : `${data.municipality}`}
                </span>
              </li>
            </ul>
            <hr />
            <div className="point-direction">
              <Button
                className="direction-btn"
                onClick={() => {
                  handleDirection();
                }}
              >
                <img src={getdirection} alt="get-direction" />
                <span>Directions</span>
              </Button>
              <Button className="share-btn">
                <img src={share} alt="share" />
              </Button>
            </div>
          </div>
        )}
      {direction && ( // Conditional rendering to check if both direction and data are not null
        <div>
          <Direction stop={name} secondLat={srcLat} secondLng={srcLng} />
        </div>
      )}
    </>
  );
};

export default Amenitiesinfo;
