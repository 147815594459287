import React, { useState } from "react";
import "./amenity.scss";
import { useMap } from "react-map-gl/maplibre";
import cross from "../../assets/images/cross.png";
import { useMapContext } from "../context/Mapcontext";

import Amenitiesinfo from "../amenitiesoverview/amenitiesinfo/Amenitiesinfo";

const Amenitydata = ({ amenityData, selectedAmenity }) => {
  const { current: map } = useMap();
  const [info, setinfo] = useState(null);
  const {
    setMapCoordinates,
    setIsPointClickedValue,
    isPointClicked,
    clickedIndex,
    setClickedIndexValue,
    direction,
    setDirectionValue,
  } = useMapContext();

  const handleClick = (point, index) => {
    setDirectionValue(false);
    setIsPointClickedValue(true);
    if (point.coords !== undefined && point.coords !== null) {
      setMapCoordinates(point.coords[1], point.coords[0]);
      map.flyTo({
        center: point.coords,
        essential: true,
        zoom: 20,
        speed: 1,
        curve: 1,
        duration: 2000,
      });
      setinfo(point);
      setClickedIndexValue(index);
      // console.log(point);
    }
  };

  return (
    <>
      <ul className={direction ? "content hidden" : "content"}>
        {amenityData.map((point, index) => (
          <React.Fragment key={index}>
            <li
              className="amenity-data"
              onClick={() => {
                handleClick(point, index);
              }}
            >
              <span>
                {point.name ? point?.name?.split(",")[0] : selectedAmenity}
              </span>
              <br />
              <p style={{ marginBottom: "0" }}>{point.place}</p>
              <p>
                {point.road ? `${point.road}, ` : ""}
                {point.municipality}
              </p>
            </li>
            {index !== amenityData.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </ul>

      {isPointClicked && (
        <div>
          <Amenitiesinfo data={info} amenityName={selectedAmenity} />
        </div>
      )}
    </>
  );
};

export default Amenitydata;
