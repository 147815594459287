import React, { useState } from "react";
import "./verify.scss";
import Banner from "../Banner/Banner";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { verifyCodeUrl } from "../../../urls/urls";

const myStorage = window.localStorage;

const Verify = () => {
  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  let navigate = useNavigate();

  const id = myStorage.getItem("user_id");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(verifyCodeUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: number,
          user_id: id,
        }),
      });

      const result = await response.json();
      navigate("/user");
      myStorage.setItem("token", `Bearer ${result.token}`);
      console.log(result);
    } catch (error) {
      console.error("Error during verification:", error);
      // response details
      if (error.response) {
        const responseData = await error.response.json();
        console.error("Response details:", responseData);
      }
    }
  };

  return (
    <div className="verify">
      <Banner />
      <div className="verify-box">
        <div className="verify-header">
          <h4>OTP Verification</h4>
        </div>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="number">Enter the 6 digit otp</label>
          <br />
          <div className="num">
            <input
              type="number"
              id="number"
              name="number"
              value={number}
              onChange={handleChange}
              placeholder="Enter otp"
              required
            />
          </div>
          <br />
          <Button type="submit">
            <span>Confirm</span>
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Verify;
