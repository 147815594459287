const activeUrl = {
  LOCAL_SERVER: "http://192.168.5.196:5000/",
  DEVELOPMENT_SERVER: "https://route-dev.gallimap.com/",
  PRODUCTION_SERVER: "https://route-init.gallimap.com/",
  REPORTS_URL: "https://reportsapi-uat.gallimap.com/",
  PANO_URL: "https://image-init.gallimap.com/",
  MAP_STYLE_URL: "https://map-init.gallimap.com/",
  EVENT_URL: "https://auth-init.gallimap.com/",
};

export default activeUrl;
